import * as React from "react";
import { useNavigate } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import HelpForm from "./HelpForm";
import styles from "./Header.module.scss";
import Cookies from "js-cookie";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

import LiveHelpIcon from "@mui/icons-material/LiveHelp";

function ResponsiveAppBar() {
  let navigate = useNavigate();
  

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [openHelp, setOpenHelp] = React.useState<boolean>(false);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const logOut = () => {
    Cookies.set("token", "");
    axios.defaults.headers.common.Authorization = "";
    window.location.replace("/");
    handleCloseUserMenu();
  };

  const myPlan = () => {
    window.location.replace("/my-plan");
    handleCloseUserMenu();
  };
  const myTutorials = () => {
    window.location.replace("/tutorials");
    handleCloseUserMenu();
  };

  const getUserData = useQuery(["user"], async () => {
    if (Cookies.get("token")) {
      const { data } = await axios.get(`/user`);
      if (data[1].initial_login) {
        navigate("/tutorials");
      }
      return data[1];
    }
    return null;
  });

  const { data: isSuperAdminData } = useQuery(
    ["isSuperAdmin"],
    async () => {
      const { data } = await axios.get("/user/is_super_admin");
      return data;
    },
    {
      enabled: !!getUserData.data,
    }
  );

  const isAdmin = useQuery(
    [''],
    async () => {
        const { data } = await axios.get('/user/is_admin');
        return data;
      },
      {
        enabled: !!getUserData.data,
      }
);

  const getPracticeData = useQuery(
    ["practice", getUserData.data?.medicube_practice_id],
    async () => {
      const { data } = await axios.get(
        `/practice/${getUserData.data?.medicube_practice_id}`
      );
      return data;
    },
    {
      enabled: !!getUserData.data?.medicube_practice_id,
    }
  );

  return (
    <>
      <AppBar className={styles.header} position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <img
                src="../logo.png"
                alt="MEDICUBE"
                onClick={() => (window.location.href = "/dashboard")}
              />
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem onClick={() => setOpenHelp(true)}>
                  <Typography textAlign="center">Help</Typography>
                </MenuItem>
              </Menu>

              <Box sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
                <img
                  src="../logo.png"
                  alt="MEDICUBE"
                  onClick={() => (window.location.href = "/dashboard")}
                />
              </Box>
            </Box>

            <Box
              sx={{
                flexGrow: 1,
                justifyContent: "flex-end",
                marginRight: "16px",
                display: { xs: "none", md: "flex" },
              }}
            >
              <Typography textAlign="center">
                {getUserData?.data?.first_name}{" "}
                {getUserData?.data?.[1]?.last_name}{" "}
                {getPracticeData?.data?.name
                  ? `(${getPracticeData?.data?.name})`
                  : ""}
              </Typography>
            </Box>
            {!!getUserData.data && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar
                      alt={`${getUserData.data.first_name} ${getUserData.data.last_name}`}
                      src={getUserData.data.image_url}
                    />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Help">
                  <Button onClick={() => setOpenHelp(true)} sx={{ mr: 2 }}>
                    <LiveHelpIcon sx={{ color: "darkgreen" }} />
                  </Button>
                </Tooltip>

                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem
                    onClick={() => {
                      navigate(`/templates`);
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">
                      My Templates
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      navigate(`/video-gallery`);
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">Video Gallery</Typography>
                  </MenuItem>
                  <MenuItem onClick={myPlan}>
                    <Typography textAlign="center">My Plan</Typography>
                  </MenuItem>
                  <MenuItem onClick={myTutorials}>
                    <Typography textAlign="center">My Tutorials</Typography>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      setOpenHelp(true);
                      handleCloseUserMenu();
                    }}
                  >
                    <Typography textAlign="center">Help</Typography>
                  </MenuItem>

                  {isSuperAdminData && (
                    <MenuItem
                      onClick={() => {
                        navigate(`/homepage_admin`);
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">Super Admin Portal</Typography>
                    </MenuItem>
                  )}

                  {isAdmin && (
                    <MenuItem
                      onClick={() => {
                        navigate(`/homepage_user`);
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">Admin Portal</Typography>
                    </MenuItem>
                  )} 

                    
                    {/* <MenuItem
                      onClick={() => {
                        navigate(`/capture`);
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">Image Capture</Typography>
                    </MenuItem> */}
                  
                  

                  {/* {isSuperAdminData && (
                    <MenuItem
                      onClick={() => {
                        window.open(
                          process.env.REACT_APP_ADMIN_ANALYTICS_URL,
                          "_blank"
                        );
                        handleCloseUserMenu();
                      }}
                    >
                      <Typography textAlign="center">Admin Portal</Typography>
                    </MenuItem>
                  )} */}

                  <MenuItem onClick={logOut}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <HelpForm openHelp={openHelp} setOpenHelp={setOpenHelp} />
    </>
  );
}
export default ResponsiveAppBar;
