import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import queryString from "query-string";
import { Form } from "react-final-form";
import * as Yup from "yup";
import Camera from "./CameraCpature/Camera";

import styles from "./Templates.module.scss";
import cx from "classnames";
import { Button, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import CreateConsentTemplateForm from "./CreateConsentTemplateForm";

import {
  TextField as RffTextField,
  Select as RffSelect,
  Autocomplete,
  makeValidate,
  Checkboxes,
  Radios,
} from "mui-rff";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor } from "react-draft-wysiwyg";
import { isEmpty } from "lodash";
import { confirmAlert } from "react-confirm-alert";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Modal, Box } from "@mui/material";
import { useDropzone } from "react-dropzone";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { CircularProgress, Typography } from "@mui/material";
import TemplatesComponent from "./TemplateModel";
import UserIcon from "@mui/icons-material/Person";
import BuildingIcon from "@mui/icons-material/Business";
import GearIcon from "@mui/icons-material/Settings";
import DoneIcon from "@mui/icons-material/Done";
import { Console } from "console";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
//import ck from "../../public/ckeditor/ckeditor";

// Utility function to resize image and convert to base64

export default function Templates() {
  let navigate = useNavigate();
  const queryClient = useQueryClient();
  const clausesRef = React.useRef(null);
  const [isOpenCreateConsentModal, setIsOpenCreateConsentModal] =
    React.useState(false);
  const [category, setCategory] = React.useState<string>("Filter by Category");
  const [search, setSearch] = React.useState("");
  const [treatmentOptions, setTreatmentOptions] = React.useState<any[]>([]);
  const [currentTemplate, setCurrentTemplate] = React.useState<any>({});
  const [editorState, setEditorState] = React.useState<any>(
    EditorState.createEmpty()
  );
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [logodata, setLogoData] = React.useState({});
  const [templates, setTemplates] = useState([]);
  const [nonDefaultTemplates, setNonDefaultTemplates] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const [isCameraModalOpen, setCameraModalOpen] = useState(false);
  const [textAreaContent, setTextAreaContent] = useState("");
  const mergedTemplates = [...templates, ...nonDefaultTemplates];
  const [currntTemplateName, setCurrnttemplateName] = React.useState("");

  const editorRef = useRef(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "/ckeditor/ckeditor.js";
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      console.log("CKEditor script loaded.");
      setIsScriptLoaded(true);
    };

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded) {
      const interval = setInterval(() => {
        if (editorRef.current) {
          console.log("Initializing CKEditor...");

          if (window.CKEDITOR.instances.editor1) {
            window.CKEDITOR.instances.editor1.destroy();
          }

          editorRef.current.value = "";
          window.CKEDITOR.replace(editorRef.current, {});

          clearInterval(interval);
        }
      }, 100);

      return () => {
        if (window.CKEDITOR && window.CKEDITOR.instances.editor1) {
          window.CKEDITOR.instances.editor1.destroy();
          delete window.CKEDITOR.instances.editor1;
        }
      };
    }
  }, [isScriptLoaded]);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const bottomReached =
        window.innerHeight + window.scrollY >= document.body.scrollHeight - 10;
      setIsBottom(bottomReached);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScrollButtonClick = () => {
    if (isBottom) {
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to top
    } else {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" }); // Scroll to bottom
    }
  };

  const getUserData = useQuery(["templateUser"], async () => {
    if (Cookies.get("token")) {
      const { data } = await axios.get(`/user`);
      return data;
    }
    return null;
  });

  const categories = useQuery(["categories"], async () => {
    const { data } = await axios.post(`/treatment_category`);
    return data;
  });

  const treatmentPlans = useQuery(
    ["treatmentPlans"],
    async () => {
      const { data } = await axios.get(`/treatment`);
      return data;
    },
    {
      onSuccess: (data) => {
        const plans = data.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
          value: plan.id,
        }));

        setTreatmentOptions(plans);
      },
    }
  );

  const scrollToTop = () => {
    if (clausesRef.current) {
      clausesRef.current.scrollTop = 0;
    }
  };

  const allowedFonts = [
    "Arial",
    "Georgia",
    "Impact",
    "Tahoma",
    "Times New Roman",
    "Verdana",
  ];

  const defaultFont = "Georgia";
  const defaultFontSize = "16px";

  const updateConsentTemplate = useMutation(
    (data) => {
      data.body = editorRef.current.value;

      const parser = new DOMParser();
      const doc = parser.parseFromString(data.body, "text/html");

      const traverseAndModify = (node) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
          for (let attr of node.attributes) {
            if (!["style", "href", "src"].includes(attr.name)) {
              node.removeAttribute(attr.name);
            }
          }

          const spanElements = node.querySelectorAll("span");
          spanElements.forEach((element) => {
            const fontFamily = element.style.fontFamily || defaultFont;
            const fontSize = "medium";

            element.style.fontFamily = allowedFonts.includes(fontFamily)
              ? fontFamily
              : defaultFont;
            element.style.fontSize = fontSize;
          });

          const allElements = node.querySelectorAll("*");
          allElements.forEach((element) => {
            const fontSize = window.getComputedStyle(element).fontSize;
            if (fontSize.endsWith("pt") || fontSize.endsWith("px")) {
              element.style.fontSize = "medium";
            }
          });
        }
        node.childNodes.forEach(traverseAndModify);
      };

      traverseAndModify(doc.body);
      const serializer = new XMLSerializer();
      data.body = serializer.serializeToString(doc.body);

      setCurrnttemplateName(data.name);

      return axios.put(`/consent_clause/${currentTemplate.id}`, data);
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["consentClauses"] });
        toast.success("Consent clause updated successfully");
        consentClauses.refetch();

        editorRef.current.value = data.body;

        setTimeout(() => {
          const textToSelect = `${currntTemplateName}`;
          const elements = document.querySelectorAll("*");
          elements.forEach((element) => {
            if (
              element.textContent &&
              element.textContent.includes(textToSelect)
            ) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });
              setCurrnttemplateName("");
            }
          });
        }, 1000);
      },
      onError: () => {
        toast.error("Something went wrong please try again");
      },
    }
  );

  const validateConsentClause = async (data) => {
    try {
      const response = await axios.put("consent_clause/validation_check", {
        id: data.id,
        treatment_ids: data.treatment_ids,
        level: data.level,
      });

      return response.data;
    } catch (error) {
      return {
        success: false,
        message: "Validation failed",
        consent_clause_name: "",
      };
    }
  };

  const handleFormSubmit = async (data) => {

    const {
      treatment_ids,
      user_id,
      practice_id,
      is_archived,
      id,
      updated_at,
      treatment_category_id,
      created_at,
      type,
      ...formData
    } = data;

    const treatment_id =
      treatment_ids && treatment_ids.length > 0 ? treatment_ids[0] : null;

    const requestData = {
      ...formData,
      treatment_id: treatment_id,
      treatment_ids: treatment_ids,
      body: editorRef.current.value,
      category_id: data.category_id,
      is_default: data.is_default,
      level: data.level,
    };

    if (!data.is_default) {
      updateConsentTemplate.mutate(requestData);
    }

    if (data.is_default) {
      const validationResponse = await validateConsentClause(data);

      if (validationResponse.result === true) {
        updateConsentTemplate.mutate(requestData);
      } else {
        let existingTreatments = "";

        if (Array.isArray(validationResponse.data)) {
          existingTreatments = validationResponse.data
            .map((item) => `<li>${item.consent_clause_name}</li>`)
            .join("\n");
        } else {
          console.error("Expected validationResponse.data to be an array.");
        }

        const existingTreatmentsList = `<ul class="custom-list">${existingTreatments}</ul>`;
        const note = `<span>(Note that if you proceed to save, the current consent clause will be made
          default and any other consent clauses will be removed as default for the above treatments)</span>`;
        const abc = `<ul class="custom-list" style="text-align: left;"><strong></strong> ${existingTreatments}</ul>`;
        const title = `One or more selected treatments have been set default in another
                consent clause`;

        confirmAlert({
          title: <div dangerouslySetInnerHTML={{ __html: title }} />,
          message: (
            <div>
              <br />
              <br />
              <div dangerouslySetInnerHTML={{ __html: abc }} />
              <br />
              <hr />
              <div dangerouslySetInnerHTML={{ __html: note }} />
            </div>
          ),
          buttons: [
            {
              label: "Cancel",
              onClick: () => {},
            },
            {
              label: "Save Anyway",
              onClick: () => {
                updateConsentTemplate.mutate(requestData);
              },
            },
          ],
        });
      }
    }
  };

  const deleteConsentTemplate = useMutation(
    (id: any) => {
      return axios.delete(`/consent_clause/${id}`);
    },
    {
      onSuccess: (data, id) => {
        if (currentTemplate.id === id) {
          setCurrentTemplate({});
          setEditorState(EditorState.createEmpty());
        }
        queryClient.invalidateQueries({ queryKey: ["consentClauses"] });
      },
    }
  );

  function scrollToText(searchText) {
    const elements = clausesRef?.current?.querySelectorAll("*");
    for (var i = 0; i < elements.length; i++) {
      if (elements[i].textContent.includes(searchText)) {
        elements[i].scrollIntoView({ behavior: "smooth", block: "center" });
        break;
      }
    }
  }

  const cloneConsentTemplate = useMutation(
    (clause: { id: any; name: string }) => {
      return axios.put(`/consent_clause/${clause.id}/clone`);
    },
    {
      onSuccess: async (data, clause) => {
        setCurrentTemplate(data.data);
        queryClient.invalidateQueries({ queryKey: ["consentClauses"] });
        scrollToTop();
        setTimeout(() => {
          const textToSelect = `Clone of ${clause.name}`;
          const elements = document.querySelectorAll("*");
          elements.forEach((element) => {
            if (
              element.textContent &&
              element.textContent.includes(textToSelect)
            ) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });

              // const selection = window.getSelection();
              // const range = document.createRange();
              // range.selectNodeContents(element);
              // selection.removeAllRanges();
              // selection.addRange(range);
            }
          });
        }, 3000);
      },
    }
  );

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append(`file`, file);
      axios
        .post(`/consent_clause/upload_image_to_s3`, formData)
        .then((response) => {
          resolve({ data: { link: response.data } });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const schema = Yup.object().shape({
    name: Yup.string().required(),
    treatment_id: Yup.string().required(),
    category_id: Yup.string().required(),
  });

  const isAdmin = useQuery(["isAdmin"], async () => {
    const { data } = await axios.get("/user/is_admin");

    return data;
  });

  const openModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    getPracticeConsentClauseData();
  }, []);

  const getPracticeConsentClauseData = async () => {
    try {
      const { data } = await axios.get("/user");
      const practiceId = data[1].medicube_practice_id;
      const response = await axios.get(
        `/practice/${practiceId}/get_practice_consent_clause_data`
      );
      setLogoData(response.data);
    } catch (error) {
      console.error("Error fetching practice consent clause data:", error);
    } finally {
      setIsModalOpen(false);
    }
  };

  const closeModal = () => {
    getPracticeConsentClauseData();
    setIsModalOpen(false);
  };

  // Run the makeValidate function...
  const validate = makeValidate(schema);

  useEffect(() => {
    if (isEmpty(currentTemplate) && templates.length > 0) {
      setCurrentTemplate(templates[0]);
      const txt = templates[0]?.body || "";
      const blocksFromHtml = htmlToDraft(txt);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap
      );
      const sampleEditorContent = EditorState.createWithContent(contentState);
      setEditorState(sampleEditorContent);
    }
  }, [templates, currentTemplate]);

  const handleCreateConsentModalClose = () => {
    setIsOpenCreateConsentModal(false);
    console.log("in coldse");
    consentClauses.refetch();
  };

  const consentClauses = useQuery(
    ["consentClauses", category, search],
    async () => {
      const params = {
        category:
          category === "All" || category === "Filter by Category"
            ? undefined
            : category,
        search,
      };
      const stringified = queryString.stringify(params);
      const { data } = await axios.get(`/consent_clause?${stringified}`);
      const uniqueNonDefaultTemplates =
        data.non_default_consent_form_templates.filter(
          (template, index, self) =>
            index === self.findIndex((t) => t.id === template.id)
        );

      setTemplates(data.consent_form_templates);
      setNonDefaultTemplates(uniqueNonDefaultTemplates);
      console.log(data);
      return data;
    },
    {
      onSuccess: (data) => {
        if (isEmpty(currentTemplate) && data.length) {
          setCurrentTemplate(data.consent_form_templates[0]);
        }

        if (!isEmpty(currntTemplateName)) {
          const selectedTemplateName = currntTemplateName;
          const foundTemplate = mergedTemplates.find(
            (template) => template.name === selectedTemplateName
          );

          if (foundTemplate) {
            setCurrentTemplate(foundTemplate);

            const txt = foundTemplate.body || "";

            if (editorRef.current) {
              editorRef.current.value = txt;
            }
          }
        }
      },
    }
  );

  useEffect(() => {
    if (currentTemplate) {
      const txt = currentTemplate.body || "";

      if (editorRef.current) {
        editorRef.current.value = txt;
      }

      if (window.CKEDITOR && window.CKEDITOR.instances.editor1) {
        window.CKEDITOR.instances.editor1.setData(txt);
      } else {
        console.warn("CKEditor instance not found or not ready.");
      }
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, [currentTemplate]);

  const selectNew = (templateName) => {
    setIsOpenCreateConsentModal(false);
    setCurrnttemplateName(templateName);
    queryClient.invalidateQueries(["consentClauses"]);
  };

  return (
    <div className={styles.templatesPage}>
      <h1 className={styles.header}>My Consent Clauses</h1>
      <div className={styles.templates}>
        <div className={styles.leftPanel}>
          <TextField
            label="Search"
            variant="filled"
            onChange={(event: any) => {
              setSearch(event.target.value);
            }}
          />
          <Select
            value={category}
            labelId="category-select-label"
            onChange={(e) => setCategory(e.target.value)}
            label="Filter by Category"
            variant="filled"
          >
            <MenuItem value="Filter by Category">Filter by Category</MenuItem>
            <MenuItem value="All">All</MenuItem>
            {categories.data?.map((category: any) => (
              <MenuItem key={category.id} value={category.id}>
                {category.category_name}
              </MenuItem>
            ))}
          </Select>
          <hr />

          <Modal open={isModalOpen} onClose={closeModal}>
            <Box>
              <TemplatesComponent closeModal={closeModal} />
            </Box>
          </Modal>

          <div className={styles.clauses} ref={clausesRef}>
            {isAdmin ? (
              <Button
                type="button"
                variant="contained"
                className={styles.createNew}
                onClick={openModal}
                ma
              >
                Upload Practice Name and Logo
                <CloudUploadIcon className={styles.icon} />
              </Button>
            ) : null}

            {templates.map((clause, index) => (
              <div
                key={clause.id}
                className={cx(styles.clauseItem, {
                  [styles.selectedClause]: clause.id === currentTemplate.id,
                  [styles.blueBackground]: index < 2,
                })}
                onClick={() => {
                  setCurrentTemplate(clause);
                  const txt = clause?.body || "";

                  if (editorRef.current) {
                    editorRef.current.value = txt;
                  }

                  const blocksFromHtml = htmlToDraft(txt);
                  const { contentBlocks, entityMap } = blocksFromHtml;
                  const contentState = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                  );
                  const sampleEditorContent =
                    EditorState.createWithContent(contentState);

                  setEditorState(sampleEditorContent);
                  if (window.CKEDITOR && window.CKEDITOR.instances.editor1) {
                    window.CKEDITOR.instances.editor1.setData(txt);
                  }
                }}
              >
                <div className={styles.iconSection}>
                  <div className={styles.icon}>
                    {clause.is_default && (
                      <DoneIcon className={styles.defaultIcon} />
                    )}
                    {clause.level === "user" ? (
                      <UserIcon />
                    ) : clause.level === "practice" ? (
                      <BuildingIcon />
                    ) : (
                      <DoneIcon />
                    )}
                  </div>
                </div>

                <div className={styles.nameSection}>
                  <Typography variant="h6" className={styles.clauseName}>
                    {clause.name}
                  </Typography>
                </div>

                <div className={styles.clauseActions}>
                  {index >= 2 && (
                    <>
                      <DeleteIcon
                        className={styles.deleteIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          confirmAlert({
                            title: "Confirm to delete",
                            message:
                              "Are you sure to delete this consent clause?",
                            buttons: [
                              {
                                label: "Yes",
                                onClick: () =>
                                  deleteConsentTemplate.mutate(clause.id),
                              },
                              {
                                label: "No",
                                onClick: () => {
                                  return;
                                },
                              },
                            ],
                          });
                        }}
                      />
                      <FileCopyIcon
                        className={styles.cloneIcon}
                        onClick={(e) => {
                          e.stopPropagation();
                          cloneConsentTemplate.mutate(clause);
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            ))}

            <Box mt={0}>
              <hr></hr>
              <div className={styles.divider}>Archived Clauses</div>
              <hr></hr>
            </Box>

            {nonDefaultTemplates.map((clause, index) => (
              <div
                key={clause.id}
                className={cx(styles.clauseItem, {
                  [styles.selectedClause]: clause.id === currentTemplate.id,
                })}
                onClick={() => {
                  setCurrentTemplate(clause);
                  const txt = clause?.body || "";

                  if (editorRef.current) {
                    editorRef.current.value = txt;
                  }

                  const blocksFromHtml = htmlToDraft(txt);
                  const { contentBlocks, entityMap } = blocksFromHtml;
                  const contentState = ContentState.createFromBlockArray(
                    contentBlocks,
                    entityMap
                  );
                  const sampleEditorContent =
                    EditorState.createWithContent(contentState);

                  setEditorState(sampleEditorContent);

                  if (window.CKEDITOR && window.CKEDITOR.instances.editor1) {
                    window.CKEDITOR.instances.editor1.setData(txt);
                  }
                }}
              >
                <div className={styles.iconSection}>
                  <div className={styles.icon}>
                    {clause.is_default && (
                      <DoneIcon className={styles.defaultIcon} />
                    )}
                    {clause.level === "user" ? (
                      <UserIcon />
                    ) : clause.level === "practice" ? (
                      <BuildingIcon />
                    ) : (
                      <DoneIcon />
                    )}
                  </div>
                </div>

                <div className={styles.nameSection}>
                  <Typography variant="h6" className={styles.clauseName}>
                    {clause.name}
                  </Typography>
                </div>

                <div className={styles.clauseActions}>
                  <DeleteIcon
                    className={styles.deleteIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      confirmAlert({
                        title: "Confirm to delete",
                        message: "Are you sure to delete this consent clause?",
                        buttons: [
                          {
                            label: "Yes",
                            onClick: () =>
                              deleteConsentTemplate.mutate(clause.id),
                          },
                          {
                            label: "No",
                            onClick: () => {},
                          },
                        ],
                      });
                    }}
                  />
                  <FileCopyIcon
                    className={styles.copyIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      cloneConsentTemplate.mutate(clause);
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={styles.rightPanel}>
          <Button
            type="button"
            variant="contained"
            className={styles.createNew}
            onClick={() => setIsOpenCreateConsentModal(true)}
          >
            + Create New Consent Clause
          </Button>
          {currentTemplate.id && (
            <Form
              onSubmit={handleFormSubmit}
              validate={(values) => {
                if (values.type !== "custom") {
                  return {};
                }
                return validate(values);
              }}
              initialValues={currentTemplate}
              render={({ handleSubmit }) => (
                <form
                  className={styles.headerForm}
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <RffTextField
                    label="Name"
                    name="name"
                    required={true}
                    variant="outlined"
                    disabled={currentTemplate.type !== "custom"}
                  />
                  {currentTemplate.type === "custom" && (
                    <>
                      <RffSelect
                        name="category_id"
                        label="Category"
                        required
                        formControlProps={{ margin: "normal" }}
                      >
                        {categories?.data?.map((category) => (
                          <MenuItem value={category.id} key={category.id}>
                            {category.category_name}
                          </MenuItem>
                        ))}
                      </RffSelect>

                      {/* <Autocomplete
                              name="treatment_id" label="Treatment"
                              required={true}
                              options={treatmentOptions}
                              getOptionValue={option => option.value}
                              getOptionLabel={option => option.label}
                            /> */}

                      <Autocomplete
                        name="treatment_ids"
                        label="Treatment"
                        multiple
                        required={true}
                        options={treatmentOptions || treatmentPlans}
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                        getOptionSelected={(option, value) =>
                          option.value === value
                        }
                      />
                    </>
                  )}
                  {/* <div className={styles.editorContainer}>
                    <Editor
                      editorState={editorState}
                      wrapperClassName={styles.editor}
                      editorClassName="demo-editor"
                      onEditorStateChange={setEditorState}
                      toolbar={{
                        image: {
                          urlEnabled: true,
                          uploadEnabled: true,
                          alignmentEnabled: true,
                          uploadCallback: uploadCallback,
                          previewImage: true,
                          inputAccept:
                            "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                          alt: { present: false, mandatory: false },
                          defaultSize: {
                            height: "auto",
                            width: "450px",
                          },
                        },
                      }}
                    />
                  </div> */}

                  <div>
                    <textarea ref={editorRef} />
                  </div>

                  {currentTemplate.type === "custom" && (
                    <>
                      <Radios
                        label="Level"
                        name="level"
                        required={true}
                        data={[
                          { label: "User", value: "user" },
                          {
                            label: "Practice",
                            value: "practice",
                            disabled: !isAdmin?.data,
                          },
                        ]}
                      />
                      <Checkboxes
                        label=""
                        name="is_default"
                        required={true}
                        data={{ label: "Save as default", value: true }}
                      />
                    </>
                  )}
                  <Button
                    className={styles.submitButton}
                    type="submit"
                    variant="contained"
                    disabled={updateConsentTemplate.isLoading}
                    //onClick={() => handleFormSubmit(currentTemplate)}
                  >
                    Save
                  </Button>
                </form>
              )}
            />
          )}
        </div>
      </div>

      <CreateConsentTemplateForm
        isOpen={isOpenCreateConsentModal}
        setCurrentTemplate={(template) => {
          selectNew(template);
        }}
        close={handleCreateConsentModalClose}
        categories={categories.data}
        treatmentPlans={treatmentOptions}
      />

      {isMobile && (
        <button
          className={styles.scrollToBottom}
          onClick={handleScrollButtonClick}
        >
          {isBottom ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </button>
      )}
    </div>
  );
}
