import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import axios from "axios";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import PrivacyPolicyModal from "./privacyPolicy/PrivacyPolicyModal";
import SaasModal from "./privacyPolicy/SaasModel";
import EulaModal  from "./privacyPolicy/eulaModal";

import styles from "./Login.module.scss";

function Login() {
  const [isMobile, setIsMobile] = useState(false);
  const [isCapture, setIsCapture] = useState(false);

  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showSAASModal, setShowSAASModal] = useState(false);
  const [showEulaModal, setShoweulaModal] = useState(false);

  const [privacyContent, setPrivacyContent] = useState("");
  const [SAASContent, setSAASContent] = useState("");
  const [eulaContent, setEulaContent] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  const login = useMutation(
    () => {
      const capture = isCapture ? "capture" : "default";
      return axios.get(`/auth/get_dentally_request_auth_link?capture=${capture}`);
    },
    {
      onSuccess: (data) => {
        window.location.replace(data.data);
      },
    }
  );

  const handlePrivacyClick = async () => {
    try {
      const response = await axios.get(`document_agreement/3`);
      setPrivacyContent(response.data.document); 
      setShowPrivacyModal(true);
    } catch (error) {
      setPrivacyContent("Failed to load Privacy Policy.");
      setShowPrivacyModal(true);
    }
  };

  const handleSAASClick = async () => {
    try {
      const response = await axios.get("document_agreement/2");
      setSAASContent(response.data.document); 
      setShowSAASModal(true);
    } catch (error) {
      setSAASContent("Failed to load Privacy Policy.");
      setShowSAASModal(true);
    }
  };

  const handleEulaClick = async () => {
    try {
      const response = await axios.get("document_agreement/1");
      setEulaContent(response.data.document); 
      console.log(response.data.document)
      setShoweulaModal(true);
    } catch (error) {
      setEulaContent("Failed to load Privacy Policy.");
      setShoweulaModal(true);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.login}>
        <div className={styles.buttonContainer}>
          <Button
            className={styles.loginButton}
            onClick={() => login.mutate()}
            disabled={login.isLoading}
          >
            <p>Click here to login via</p>
            <img src="../dentally.jpg" alt="Dentally Logo" />
          </Button>

          {/* <Box ml={14} mb={6}>
            {isMobile ? <p className={styles.orText}>Or</p> : null}
          </Box> */}

          {/* <Box ml={4}>
            {isMobile ? (
              <Button
                type="button"
                variant="contained"
                className={styles.createNew}
                onClick={() => {
                  setIsCapture(true);
                  login.mutate();
                }}
              >
                Capture Images
                <AddPhotoAlternateIcon className={styles.icon} />
              </Button>
            ) : null}
          </Box> */}
        </div>
      </div>

      <footer className={styles.footer}>
        <div className={styles.footerContent}>
          <img src="/logo.png" alt="Company Logo" className={styles.logo} />
          <div className={styles.divider}></div>
          <div>
            <p>&copy; 2024 Medicube. All rights reserved.</p>
            <p>
              <a onClick={handlePrivacyClick} className={styles.link}>
                Privacy Policy
              </a>{" "}
              |
              <a onClick={handleSAASClick} className={styles.link}>
                SAAS
              </a>{" "}
              |
              <a onClick={handleEulaClick} className={styles.link}>
                EULA
              </a>
            </p>
          </div>
        </div>
      </footer>

      <PrivacyPolicyModal
        open={showPrivacyModal}
        onClose={() => setShowPrivacyModal(false)}
        content={privacyContent}
      />

      <SaasModal
        open={showSAASModal}
        onClose={() => setShowSAASModal(false)}
        content={SAASContent}
      />

      <EulaModal
        open={showEulaModal}
        onClose={() => setShoweulaModal(false)}
        content={eulaContent}
      />
    </div>
  );
}

export default Login;
