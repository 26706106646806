import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import styles from "./ConsentDetails.module.scss";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useParams } from "react-router-dom";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import cx from "classnames";
import { toast } from "react-toastify";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
} from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { findIndex } from "lodash";
import PdfViewer from "./PdfViewer";
import LoadingButton from "@mui/lab/LoadingButton";
import FormModal from "./Fp17Form/DentalTreatmentModal";
import ReactPlayer from "react-player";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { useRef } from "react";

const steps = [
  "Consent Details",
  "Treatment Identified",
  "Consent form",
  "Videos",
];

const customStyleMap = {
  "rdw-center-aligned-block": {
    textAlign: "center",
  },
};

export default function ConsentDetails() {
  const queryClient = useQueryClient();
  let navigate = useNavigate();
  let { consentBundleId } = useParams();
  const [treatmentOptions, setTreatmentOptions] = React.useState<any[]>([]);
  const [selectedPlans, setSelectedPlans] = React.useState<any[]>([]);
  const [value, setValue] = React.useState(1);
  const [activeStep, setActiveStep] = React.useState(0);
  const [editorState, setEditorState] = React.useState<any>(
    EditorState.createEmpty()
  );
  const [data, setData] = React.useState<any>(null);
  const [pdfUrl, setPdfUrl] = React.useState("");
  const [isOpenPdfViewer, setIsOpenPdfViewer] = React.useState(false);
  const [isLoadingViewPdf, setIsLoadingViewPdf] = React.useState(false);
  const [isUpdatingPrice, setIsUpdatingPrice] = React.useState(false);
  const [isRequirefp17dc, setIsRequirefp17dc] = React.useState(false);
  const [pageLoaded, setPageLoaded] = React.useState(false);

  const [practitionerList, setPractitionerList] = useState([]);
  const [isLoadingPractitioners, setIsLoadingPractitioners] = useState(true);
  const [selectedPractitioner, setSelectedPractitioner] = useState(null);

  const user = queryClient.getQueryData(["user"]);

  //video
  const [selectedVideo, setSelectedVideo] = React.useState<any>(null);
  const [currentVideoIndex, setCurrentVideoIndex] = React.useState(0);
  //const [selectedVideoIndex, setSelectedVideoIndex] = React.useState<any>(0);
  const videoRef = useRef(null);


  
  const treatmentDetails = useQuery(
    ["treatmentDetails"],
    async () => {
      const { data } = await axios.get(`/consent_bundle/${consentBundleId}`);
      setIsRequirefp17dc(data.require_fp17dc);
      
      return data;
    },
    {
      onSuccess: async (data) => {
        setData(data);
        if (data.status !== "Draft") {
          navigate(`/${data.id}/summary`);
        }

        const { data: practitionerData } = await axios.get(
          `/consent_bundle/${data.id}/get_practitioner_list_for_consent_bundle`
        );
        setPractitionerList(practitionerData);
      
        if (data?.clinician_id && practitionerData?.length > 0) {
          const matchedPractitioner = practitionerData.find(
            (practitioner) => practitioner.dentally_id === data.clinician_id
          );
          if (matchedPractitioner) {
            setSelectedPractitioner(matchedPractitioner.id); // Auto-select the practitioner
          }
        }
        const plans = data.treatment_plan.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
        }));

        const txt = data?.consent_form?.body || "";
        const blocksFromHtml = htmlToDraft(txt);
        const { contentBlocks, entityMap } = blocksFromHtml;
        const contentState = ContentState.createFromBlockArray(
          contentBlocks,
          entityMap
        );
        const sampleEditorContent = EditorState.createWithContent(contentState);

        setEditorState(sampleEditorContent);
        setSelectedPlans(plans);

        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },

      enabled: !!consentBundleId,
      refetchOnWindowFocus: false,
      staleTime: 480000,
    }
  );

  const handlePractitionerChange = (e) => {
    const selectedId = e.target.value;
    setSelectedPractitioner(selectedId);

    if (selectedId) {
      toast.success(`Saved practitioner: ${e.target.options[e.target.selectedIndex].text}`);
    }
  };
  React.useEffect(() => {
    if (data && data.playlists && data.playlists.length > 0) {
      const playListIndex = findIndex(data.playlists, { status: true });
      const playlist = data?.playlists?.[playListIndex]?.playlist_items || [];
      const currentVideo = playlist?.[currentVideoIndex] || null;
      setSelectedVideo(currentVideo);
    }
  }, [data, currentVideoIndex]);

  React.useEffect(() => {
    setPageLoaded(true);
  }, []);

  const handleVideoEnd = () => {
    playNextVideo();
  };

  const playNextVideo = () => {
    setCurrentVideoIndex((prevIndex) => prevIndex + 1);
  };

  const addNewTreatment = useMutation(
    (treatmentId) => {
      return axios.put(
        `/consent_bundle/${treatmentDetails.data.id}/add_treatment/${treatmentId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },
    }
  );

  const deleteTreatment = useMutation(
    (treatmentId: number) => {
      return axios.delete(
        `/consent_bundle/${treatmentDetails.data.id}/remove_treatment/${treatmentId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },
    }
  );

  const refreshTreatments = useMutation(
    () => {
      return axios.patch(
        `/consent_bundle/${treatmentDetails.data.id}/refresh_treatment_items`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        queryClient.invalidateQueries({ queryKey: ["treatmentPlans"] });
      },
    }
  );

  const deleteVideo = useMutation(
    (videoId: number) => {
      return axios.put(
        `/consent_bundle/${treatmentDetails.data.id}/toggle_visibility_playlist_item/${videoId}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
      },
    }
  );

  const deletePlaylist = useMutation(
    (id: number) => {
      return axios.put(
        `/consent_bundle/${treatmentDetails.data.id}/toggle_visibility_playlist/${id}`
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
      },
    }
  );

  const sendForSigning = useMutation(
    (consentForm: any) => {
      return axios.post(
        `/consent_bundle/${treatmentDetails.data.id}/send_consent_bundle_for_signing`,
        { consentForm }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["treatmentDetails"] });
        window.location.href = `/${consentBundleId}/thankyou`;
      },
    }
  );

  const logData = useMutation((data: any) => {
    return axios.post(`/log`, data);
  });

  const uploadCallback = (file) => {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append(`file`, file);
      axios
        .post(`/consent_clause/upload_image_to_s3`, formData)
        .then((response) => {
          resolve({ data: { link: response.data } });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const sendForSigningFunction = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    sendForSigning.mutate(markup);
  };

  const previewPdf = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());

    const consentForm = draftToHtml(rawContentState);
    setIsLoadingViewPdf(true);
    axios({
      url: `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/generate_preview_pdf`,
      method: "POST",
      responseType: "blob", // important
      data: { consentForm },
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);
      setPdfUrl(href);
      setIsOpenPdfViewer(true);
      setIsLoadingViewPdf(false);
    });
  };

  const refreshPricing = () => {
    setIsUpdatingPrice(true);
    axios
      .patch(
        `${process.env.REACT_APP_BASE_URL}consent_bundle/${consentBundleId}/refresh_treatment_pricing`
      )
      .then(() => {
        setIsUpdatingPrice(false);
        toast.success("Treatment Pricing Updated");
      })
      .catch(() => {
        setIsUpdatingPrice(false);
        toast.error("Failed to update treatment pricing");
      });
  };

  const treatmentPlans = useQuery(
    ["treatmentPlans"],
    async () => {
      const { data } = await axios.get(
        `/treatment?practice_id=${treatmentDetails.data.practice_id}`
      );
      return data;
    },
    {
      onSuccess: (data) => {
        const plans = data.treatments.map((plan) => ({
          ...plan,
          label: plan.description,
        }));
        setTreatmentOptions(plans);
      },
      enabled: !!treatmentDetails?.data?.practice_id,
      refetchOnWindowFocus: false,
      staleTime: 480000,
    }
  );

  const handleDelete = (plan) => {
    deleteTreatment.mutate(plan.treatment_id);
  };

  const handleSelectPlan = (newValue: any) => {
    addNewTreatment.mutate(newValue.id);
  };

  const deleteVideoFunction = (videoId) => {
    deleteVideo.mutate(videoId);
  };
  const deletePlaylistFunction = (id) => {
    deletePlaylist.mutate(id);
  };
  const [clickedVideoId, setClickedVideoId] = React.useState(null);
  const hackUpdate = (video) => {
    setClickedVideoId(video.id);
    setSelectedVideo(null);
    setTimeout(() => {
      setSelectedVideo(video);
    }, 0);
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = async () => {
    try {
      const response = await axios.get(`/consent_bundle/${consentBundleId}`);

      if (response.status === 200) {
        setFormData(response.data.fp17dc_data);
        setSelectedTeeth(response.data.fp17dc_data);
      }
      setOpen(true);
    } catch (error) {
      console.error("Error fetching consent bundle data:", error);
    }
  };

  const [showAddButton, setShowAddButton] = useState(false);
  const [fp17dcIsChecked, setFp17dcIsChecked] = useState(false);

  const handleCheckboxChangetoggle = async (event) => {
    const isChecked = event.target.checked;
    setFp17dcIsChecked(isChecked);

    setFormData({ ...formData, checkbox: isChecked });

    try {
      const response = await axios.put(
        `/consent_bundle/${consentBundleId}/toggle_fp17dc_form_generation`
      );

      setShowAddButton(response.data);
      await treatmentDetails.refetch();
    } catch (error) {
      console.error("Error updating FP17DC form generation:", error);
    }
  };

  const handleSave = async () => {
    try {
      const updatedFormData = {
        ...formData,
      };

      const response = await axios.post(
        `/consent_bundle/${consentBundleId}/save_fp17dc_form_data`,
        updatedFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status !== 200) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const result = response.data;

      toast.success("Fp17DC data saved successfully");

      if (isRequirefp17dc) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          checkbox: true,
        }));
      }

      setOpen(false);
    } catch (error) {
      console.error("Error saving form data:", error);
      toast.error(`Failed to save form data: ${error.message}`);
    }
  };

  const [modalOpen, setModalOpen] = useState(false);
  const handleClickModalOpen = () => {
    setModalOpen(true);
  };

  const handleClose = () => {
    if (isRequirefp17dc) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkbox: true,
      }));
    }
    setOpen(false);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleModalConfirm = () => {
    sendForSigningFunction();
    handleClose();
  };

  useEffect(() => {
    if (isRequirefp17dc) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        checkbox: true,
      }));
    }
  }, [isRequirefp17dc]);

  const handleInputChange = (event) => {
    const { name, type, value, checked } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? (checked ? "Yes" : "No") : value,
    }));
  };

  const [formData, setFormData] = React.useState({});

  const [selectedTeeth, setSelectedTeeth] = useState([]);

  const handleToothSelect = (tooth) => {
    setSelectedTeeth((prevSelectedTeeth) => {
      const newSelectedTeeth =
        prevSelectedTeeth[tooth] === "Yes"
          ? { ...prevSelectedTeeth, [tooth]: null }
          : { ...prevSelectedTeeth, [tooth]: "Yes" };

      setFormData((prevFormData) => ({
        ...prevFormData,
        [tooth]: newSelectedTeeth[tooth],
      }));

      return newSelectedTeeth;
    });
  };

  return (
    <div className={styles.consentContainer}>
      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Consent Details</h2>
            {treatmentDetails?.data?.patient && (
              <div className={styles.column}>
                <p className={styles.dataItem}>
                  Name: {treatmentDetails.data.patient.title}{" "}
                  {treatmentDetails.data.patient.first_name}{" "}
                  {treatmentDetails.data.patient.last_name}
                </p>
                <p className={styles.subDataItem}>
                  Email: {treatmentDetails.data.patient.email_address}
                </p>
                <p className={styles.subDataItem}>
                  Mobile: {treatmentDetails.data.patient.mobile_phone}
                </p>
                <p className={styles.subDataItem}>
                  Phone: {treatmentDetails.data.patient.home_phone}
                </p>
                <p className={styles.subDataItem}>
                  Address: {treatmentDetails.data.patient.address_line_1}{" "}
                  {treatmentDetails.data.patient.address_line_2}
                </p>
                <p className={styles.subDataItem}>
                  NHS Number: {treatmentDetails.data.patient.nhs_number}
                </p>
                <p className={styles.subDataItem}>
                  Version: {treatmentDetails.data.version_no}
                </p>

                <select
        id="practitioner"
        name="practitioner"
        className={styles.dropdown}
        onChange={handlePractitionerChange}
        value={selectedPractitioner}
      >
        <option value="">Choose Practitioner</option>
        {practitionerList && practitionerList.length > 0 ? (
          practitionerList.map((practitioner) => (
            <option key={practitioner.id} value={practitioner.id}>
              {practitioner.name}
            </option>
          ))
        ) : (
          <option value="">No practitioners available</option>
        )}
      </select>
              </div>
            )}
          </div>

          <div className={styles.footer}>
            <Button variant="outlined" color="secondary" csize="large" disabled>
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={async () => {
                if (!selectedPractitioner) {
                  alert("Please select a practitioner.");
                  return; 
                }

                try {
                  await axios.put(
                    `/consent_bundle/${consentBundleId}/set_practitioner_for_consent_bundle/${selectedPractitioner}`
                  );
                  setActiveStep(1);
                  await logData.mutateAsync({
                    type: "info",
                    description:
                      "Click next button to move from Consent Details to Treatment Identified",
                    event: "Module Access",
                    data: {
                      consentBundleId: consentBundleId,
                    },
                    practice_id: user?.medicube_practice_id,
                    user_id: user?.medicube_user_id,
                  });
                } catch (error) {
                  console.error("Error setting practitioner:", error);
                  alert("Failed to set the practitioner. Please try again.");
                }
              }}
              disabled={!pageLoaded || !selectedPractitioner}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {activeStep === 1 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Treatment Identified</h2>
            <div className={styles.treatmentSection}>
              <Autocomplete
                value={null}
                disableClearable
                onChange={(event: any, newValue: any) => {
                  handleSelectPlan(newValue);
                }}
                options={treatmentOptions}
                sx={{ width: 300 }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search other treatments"
                    variant="filled"
                  />
                )}
              />
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                onClick={() => refreshTreatments.mutate()}
              >
                Refresh
              </Button>
              {(refreshTreatments.isLoading ||
                deleteTreatment.isLoading ||
                addNewTreatment.isLoading ||
                treatmentDetails.isLoading ||
                treatmentPlans.isLoading) && <CircularProgress />}
            </div>
            <div className={styles.treatmentPlans}>
              {selectedPlans.map((plan, index) => {
                return (
                  <Chip
                    key={index}
                    label={plan.label}
                    variant="outlined"
                    onDelete={() => handleDelete(plan)}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setActiveStep(0);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click back button to move from Treatment Identified to Consent Details",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setActiveStep(2);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click next button to move from Treatment Identified to Videos",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
              disabled={!pageLoaded}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {activeStep === 2 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Consent form</h2>
          </div>
          <div className={styles.row}>
            <div className={styles.column}>
              <div
                className={cx(styles.treatmentPlans, styles.editorContainer)}
              >
                <Editor
                  editorState={editorState}
                  wrapperClassName={styles.editorWrapper}
                  editorClassName={styles.editorContent}
                  toolbarClassName={styles.editorToolbar}
                  onEditorStateChange={setEditorState}
                  customStyleMap={customStyleMap}
                  toolbar={{
                    image: {
                      urlEnabled: true,
                      uploadEnabled: true,
                      alignmentEnabled: true,
                      uploadCallback: uploadCallback,
                      previewImage: true,
                      inputAccept:
                        "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                      alt: { present: false, mandatory: false },
                      defaultSize: {
                        height: "auto",
                        width: "450px",
                      },
                    },
                  }}
                />
              </div>

              <Box
                mt={2}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <label>
                  <input
                    type="checkbox"
                    checked={formData.checkbox}
                    onChange={handleCheckboxChangetoggle}
                  />
                  Enable FP17DC
                </label>
                &nbsp;
                {showAddButton || isRequirefp17dc ? (
                  <button
                    onClick={handleOpen}
                    style={{
                      backgroundColor: "darkblue",
                      color: "white",
                      borderRadius: "20px",
                      padding: "10px 20px",
                      border: "none",
                      cursor: "pointer",
                    }}
                  >
                    Edit FP17DC
                  </button>
                ) : null}
              </Box>

              <FormModal
                open={open}
                handleClose={handleClose}
                formData={formData}
                handleInputChange={handleInputChange}
                handleSave={handleSave}
                handleCheckboxChange={handleCheckboxChangetoggle}
                handleToothSelect={handleToothSelect}
                selectedTeeth={selectedTeeth}
              />
            </div>
          </div>

          <div className={styles.footer}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setActiveStep(1);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click back button to move from Videos to Treatment Identified",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
            >
              Back
            </Button>

            <div
              className={styles.buttonGroup}
              style={{ display: "flex", gap: "16px" }}
            >
              <LoadingButton
                loading={isLoadingViewPdf}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={previewPdf}
              >
                Preview
              </LoadingButton>
              <LoadingButton
                loading={isUpdatingPrice}
                variant="outlined"
                color="secondary"
                size="large"
                onClick={refreshPricing}
              >
                Refresh Treatment Pricing
              </LoadingButton>
            </div>

            <Button
              variant="contained"
              size="large"
              onClick={() => {
                setActiveStep(3);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click next button to move from Videos to Consent form",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
              disabled={!pageLoaded}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {activeStep === 3 && (
        <div className={styles.consentSection}>
          <div className={styles.column}>
            <h2 className={styles.header}>Videos</h2>
          </div>
          {treatmentDetails?.data?.playlists?.length > 0 ? (
            <div className={styles.videos}>
              <div className={styles.playListContainer}>
                <TabContext value={value}>
                  <div className="tab-container">
                    <TabList
                      onChange={(e, v) => {
                        setValue(v);
                        logData.mutateAsync({
                          type: "info",
                          description: "Click video playlist",
                          event: "Module Access",
                          data: {
                            consentBundleId: consentBundleId,
                            playlistId:
                              treatmentDetails.data.playlists[v - 1].id,
                          },
                          practice_id: user?.medicube_practice_id,
                          user_id: user?.medicube_user_id,
                        });
                      }}
                      variant="scrollable"
                      className="custom-tab-list"
                    >
                      {treatmentDetails?.data?.playlists?.map((list, index) => {
                        if (deletePlaylist.isLoading) {
                          return (
                            <Tab
                              disabled={deletePlaylist.isLoading}
                              key={index}
                              label={list?.title}
                              value={index + 1}
                            />
                          );
                        } else if (list.status) {
                          return (
                            <Tab
                              key={index}
                              disabled={deletePlaylist.isLoading}
                              label={list?.title}
                              value={index + 1}
                              icon={
                                <VisibilityIcon
                                  onClick={() =>
                                    deletePlaylistFunction(list.id)
                                  }
                                />
                              }
                            />
                          );
                        } else {
                          return (
                            <Tab
                              key={index}
                              disabled={deletePlaylist.isLoading}
                              label={list?.title}
                              value={index + 1}
                              icon={
                                <VisibilityOffIcon
                                  onClick={() =>
                                    deletePlaylistFunction(list.id)
                                  }
                                />
                              }
                            />
                          );
                        }
                      })}
                    </TabList>
                  </div>
                  {treatmentDetails?.data?.playlists?.map(
                    (playlist, playlistsIndex) => {
                      return (
                        <TabPanel
                          className={styles.playList}
                          value={playlistsIndex + 1}
                        >
                          {playlist?.playlist_items?.map((video, index) => {
                            return (
                              <div className={styles.video} key={index}>
                                <Chip
                                  className={cx(styles.description, {
                                    [styles.active]:
                                      (index === currentVideoIndex &&
                                        !clickedVideoId) ||
                                      video.id === clickedVideoId,
                                  })}
                                  label={video.title}
                                  avatar={
                                    <img
                                      className={styles.thumbnail}
                                      src={video.thumbnail}
                                    />
                                  }
                                  variant={video.status ? "outlined" : "filled"}
                                  onDelete={() => deleteVideoFunction(video.id)}
                                  deleteIcon={
                                    video.status ? (
                                      <VisibilityIcon />
                                    ) : (
                                      <VisibilityOffIcon />
                                    )
                                  }
                                  onClick={() => {
                                    hackUpdate(video);
                                    logData.mutateAsync({
                                      type: "info",
                                      description: "Click video thumbnail",
                                      event: "Module Access",
                                      data: {
                                        consentBundleId: consentBundleId,
                                        video: video.title,
                                      },
                                      practice_id: user?.medicube_practice_id,
                                      user_id: user?.medicube_user_id,
                                    });
                                  }}
                                  disabled={deleteVideo.isLoading}
                                />
                              </div>
                            );
                          })}
                        </TabPanel>
                      );
                    }
                  )}
                </TabContext>
              </div>
              <div className={styles.player}>
                {selectedVideo ? (
                  <ReactPlayer
                    url={selectedVideo.url}
                    playing={false} // Disables autoplay
                    controls
                    width="100%"
                    height="480px"
                    onEnded={handleVideoEnd}
                  />
                ) : (
                  <p className={styles.noVideos}>No active videos to display</p>
                )}
              </div>
            </div>
          ) : (
            <p className={styles.noVideos}>
              There are no videos for you to watch; please click 'Send' to
              submit the consent form.{" "}
            </p>
          )}

          <div className={styles.footer}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setActiveStep(2);
                logData.mutateAsync({
                  type: "info",
                  description:
                    "Click back button to move from Consent form to Videos",
                  event: "Module Access",
                  data: {
                    consentBundleId: consentBundleId,
                  },
                  practice_id: user?.medicube_practice_id,
                  user_id: user?.medicube_user_id,
                });
              }}
              disabled={sendForSigning.isLoading}
            >
              Back
            </Button>

            {treatmentDetails.data.version_no > 1 ? (
              <LoadingButton
                loading={sendForSigning.isLoading}
                variant="contained"
                color="success"
                size="large"
                onClick={handleClickModalOpen}
                disabled={sendForSigning.isLoading}
              >
                Send
              </LoadingButton>
            ) : (
              <LoadingButton
                loading={sendForSigning.isLoading}
                variant="contained"
                color="success"
                size="large"
                onClick={sendForSigningFunction}
                disabled={sendForSigning.isLoading}
              >
                Send
              </LoadingButton>
            )}
          </div>

          <Dialog
            open={modalOpen}
            onClose={handleModalClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Warning</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Your patient will lose access to the previous version. Would you
                like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose} color="primary">
                No
              </Button>
              <Button onClick={handleModalConfirm} color="primary" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
      {pdfUrl && isOpenPdfViewer && (
        <PdfViewer
          isOpen={isOpenPdfViewer}
          close={() => setIsOpenPdfViewer(false)}
          pdfUrl={pdfUrl}
        />
      )}
    </div>
  );
}
